import { QueryClientProvider } from 'react-query';
import { queryClient } from './api/QueryClient';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { applicationRoutes } from './routes';
import { HomePage } from './pages/HomePage';
import { NotFound } from './pages/NotFound';
import { QuotePage } from './pages/QuotePage';
import { Mileage } from './pages/Mileage';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import ReactGA from "react-ga4";
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { useEffect } from 'react';

function App() {

  ReactGA.initialize('G-SXSZNXTS1E')

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s.skimresources.com/js/271707X1757286.skimlinks.js';

    document.body.appendChild(script);

    
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path={applicationRoutes.home} element={<HomePage />}></Route>
            <Route path={applicationRoutes.quote} element={<QuotePage />}></Route>
            <Route path={applicationRoutes.mileage} element={<Mileage />}></Route>
            <Route path={applicationRoutes.about} element={<About />}></Route>
            <Route path={applicationRoutes.contact} element={<Contact />}></Route>
            <Route path={applicationRoutes.notFound} element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
