import Typography from "@mui/material/Typography";
import { Badge, Box, Button, Chip, CircularProgress, Grid, styled } from "@mui/material";
import TrustBoxContainer from "./TrustPilotWidget";
import { StyledLogoCard, StyledOfferBox } from "../common/styles";
import { expiry } from "../common/Expiry";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DoneIcon from '@mui/icons-material/Done';
import { linkMap } from "../common/Data";
import ReactGA from "react-ga4";

const StyledQuoteBox = styled(Grid)(({ theme }) => ({
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    flex: "1 1 0",
    alignItems: "center",
    borderColor: theme.palette.secondary.light
}));


const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginRight: theme.spacing(4),
}))

const StyledGoButton = styled(Button)(({ theme }) => ({
    borderRadius: 16
}))

const StyledGridItem = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "center"
}))

interface QuoteProps {
    amount: string | undefined,
    provider: string | undefined
    quoteSize: "h4" | "h6"
}


export default function Quote({ provider, amount, quoteSize }: QuoteProps) {
    let displayString = amount
    const offerProvider = (provider == "carwow" || provider == "motorway")

    const handleClick = (platform: string) => {
        ReactGA.event({
            category: 'Referal',
            action: 'Click',
            label: provider,
        });
        ReactGA.event({
            category: 'ReferalDestination',
            action: provider!,
            label: provider,
        });
        window.open(linkMap.get(platform))
    };


    if (displayString) {
        displayString = displayString.replace(".00", "")
        displayString = displayString.replace("*", "")
    }
    if (provider) {
        return (
            <Grid item sm={12} sx={{ position: "relative" }}>
                {offerProvider &&
                    <StyledOfferBox>
                        <div style={{ color: "white" }}>£50 Amazon Voucher </div>
                    </StyledOfferBox>

                }
                <StyledQuoteBox container border={offerProvider ? "5px solid" : "0px solid"}>
                    <StyledGridItem item xs={1}>
                        <StyledLogoCard
                            sx={{ backgroundImage: `url(${process.env.PUBLIC_URL + `/${provider}.png`})` }}
                        />
                    </StyledGridItem>
                    <StyledGridItem item xs={2}>
                        {amount ? (
                            <StyledTypography variant={quoteSize}
                                color={"#2d6a4f"}
                                style={{ fontFamily: "TitleFont" }}>
                                £{displayString}
                            </StyledTypography>
                        ) : (
                            <CircularProgress></CircularProgress>
                        )}
                    </StyledGridItem>
                    <StyledGridItem item xs={2}>
                        <TrustBoxContainer provider={provider} variant="large" />
                    </StyledGridItem>
                    <StyledGridItem item xs={2}>
                        <Typography variant="h4" style={{ fontFamily: "TitleFont" }}>{expiry(provider).days} days</Typography>
                    </StyledGridItem>
                    <StyledGridItem item xs={2}>
                        {expiry(provider).settlesFinance ? <DoneIcon fontSize="large" /> : <HorizontalRuleIcon fontSize="large" />}
                    </StyledGridItem>
                    <StyledGridItem item xs={2}>
                        {expiry(provider).auction ? <DoneIcon fontSize="large" /> : <HorizontalRuleIcon fontSize="large" />}
                    </StyledGridItem>
                    <StyledGridItem item xs={1}>
                        <StyledGoButton variant="contained"
                            onClick={() => handleClick(provider)}>
                            Get Offer
                        </StyledGoButton>
                    </StyledGridItem>
                </StyledQuoteBox>
            </Grid >
        );
    }
    else {
        return <></>
    }
}
