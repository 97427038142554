import {
    Box,
    Container,
    Divider,
    IconButton,
    Link,
    Stack,
    Typography,
    styled,
    useTheme
} from "@mui/material";
import Footer from "../components/Footer";
import AppAppBar from "../components/AppAppBar";
import { AppBarDivider } from "../common/styles";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SocialIcon } from "react-social-icons";

const StyledBox = styled(Box)(({ theme }) => ({
    minHeight: "80vh",
    width: "100vw",
    overflow: "hidden",
    alignItems: "center"
}));

const BodyBox = styled(Box)(({ theme }) => ({
    maxWidth: "80%",
    overflow: "hidden"
}));

export function Contact(): React.ReactElement {
    const theme = useTheme()
    const mailTo = "mailto:info@quotorcar.co.uk?subject=Enquiry";
    const iconSize = 50
    return (
        <div style={{ width: "100%" }}>
            <StyledBox justifyContent={"space-between"}>
                <AppAppBar mode="light" />
                <AppBarDivider />
                <Container maxWidth="md">
                    <Box my={5} pl={2} pr={2}>
                        <Typography mb={4} variant="h3" gutterBottom textAlign={"center"} fontFamily={"TitleFont"}>
                            Contact Us
                        </Typography>
                        <Typography variant="body1" paragraph textAlign={"center"}>
                            We would love to hear from you! Whether you have a question, feedback, or just want to chat about car valuations, feel free to reach out.
                        </Typography>

                        <Divider sx={{ width: '100%', my: 3 }} />

                        <Typography variant="h6" gutterBottom textAlign={"center"} fontFamily={"TitleFont"}>
                            Get in Touch
                        </Typography>
                        <Typography variant="body1" textAlign={"center"} paragraph>
                            <strong>Email:</strong> <Link href={mailTo}>info@quotorcar.co.uk</Link>
                        </Typography>
                        <Typography variant="body1" textAlign={"center"} paragraph>
                            We aim to respond within 24 hours.
                        </Typography>
                    </Box>
                    <Divider sx={{ width: '100%', my: 3 }} />
                    <Typography variant="h6" gutterBottom textAlign={"center"} fontFamily={"TitleFont"}>
                        Socials
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                        useFlexGap
                    >
                        <IconButton
                            target="_blank"
                            href="https://www.linkedin.com/in/sandy-johnstone-5a6b4518b/"
                            aria-label="LinkedIn"
                            sx={{ alignSelf: 'center' }}
                        >
                            <LinkedInIcon style={{ height: iconSize, width: iconSize, color: "#0072b1" }} />
                        </IconButton>
                        <IconButton
                            target='_blank'
                            href="https://www.tiktok.com/@quotorapp"
                            aria-label="TikTok"
                            sx={{ alignSelf: 'center' }}
                        >
                            <SocialIcon style={{ height: iconSize, width: iconSize }} target="_blank" url="https://www.tiktok.com/@quotorapp" />
                        </IconButton>
                    </Stack>
                </Container>
            </StyledBox>
            <Divider sx={{ width: '100%' }} />
            <Footer />
        </div>
    );
}
