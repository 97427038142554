import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from 'react-router-dom';
import { Provider } from "../interfaces/Providers";
import Quote from "../components/Quote";
import { useQueries } from "react-query";
import { getQuote } from "../api/ScraperAPI";
import { LoadBackdrop } from "../components/LoadBackdrop";
import QuoteHeader from "../components/QuoteHeader";
import QuoteSmall from "../components/QuoteSmall";
import { useEffect, useState } from "react";
import { AppBarDivider, PageContainer, ProgressBarInside, ProgressBarOutside, RegTypography } from "../common/styles";
import ReactGA from "react-ga4";
import Footer from "../components/Footer";
import AppAppBar from "../components/AppAppBar";

const loadStrings = [
  "Contacting providers...",
  "Getting vehicle details...",
  "Retrieving quotes...",
]

const providers = Object.keys(Provider).filter((v) => isNaN(Number(v))).map((value) => {
  return value
})

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100vh",
  width: "100vw"
}));

const StyledMessageBox = styled(Box)(({ theme }) => ({
  height: "50%",
  display: "flex",
  width: "100vw",
  justifyContent: "center",
  flexDirection: "column",
}));


export function QuotePage(): React.ReactElement {
  const theme = useTheme();
  const onlyLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  const [count, setCount] = useState(0);

  let params = useParams()
  let vrm = params.vrm
  let mileage = params.mileage ? Number.parseInt(params.mileage) : undefined

  const quoteQueries = useQueries(
    providers.map((val: string) => {
      return {
        queryKey: ['quote', val],
        queryFn: () => getQuote(val, vrm!!, mileage!!),
        placeholderData: { provider: val, amount: undefined },
        retry: 2
      }
    })
  )

  useEffect(() => {
    if (count < 100) {
      const interval = setInterval(() => {
        setCount(count + Math.floor(Math.random() * 6));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [count]);

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Quote",
  });

  useEffect(() => {
    // track conversions
    if (!document.getElementById('gtag-conversion-script')) {
      const script = document.createElement('script');
      script.id = 'gtag-conversion-script';
      script.type = 'text/javascript';
      script.innerHTML = `
      gtag('event', 'conversion', {
          'send_to': 'AW-16696211817/v4O9CPCuiNEZEOnyr5k-',
          'transaction_id': ''
      });
    `;
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);


  const loaded = quoteQueries.filter(value => !value.isFetching && value.data !== undefined).length
  const failed = quoteQueries.filter(value => value.isError && !value.isFetching).length
  const allLoaded = loaded === (quoteQueries.length - failed)

  return (
    <PageContainer>
      <StyledBox width={"100%"} height={"100vh"} >
        <AppAppBar mode={"light"} />
        <AppBarDivider />
        {vrm && <Box display={"flex"} justifyContent={"center"}>
          <RegTypography variant="h4" textAlign={"center"} sx={{ fontFamily: "UKNumberPlate" }}>{vrm.toUpperCase()}</RegTypography>
        </Box>}
        {(count < 100 && loaded < 1) ? (
          <LoadBackdrop
            percentage={count}
            message={loadStrings[Math.min(Math.round((count / 30) * loadStrings.length), 2)]}
          />
        ) : (
          failed < quoteQueries.length ? (
            <Grid container spacing={2} padding={2}>
              <Box width={"100%"} mr={2} ml={2}>
                {!allLoaded && <CircularProgress size={"15px"} sx={{ marginRight: 2 }}></CircularProgress>}
                {loaded} results
                {!allLoaded &&
                  <ProgressBarOutside>
                    <ProgressBarInside sx={{ width: `${loaded / quoteQueries.length * 100}%` }} />
                  </ProgressBarOutside>
                }
              </Box>
              {onlyLargeScreen && <QuoteHeader></QuoteHeader>}
              {vrm && mileage ? (quoteQueries.sort((a, b) => (
                parseFloat(b.data?.amount ? b.data.amount.replace(",", "") : "0")
                - parseFloat(a.data?.amount ? a.data.amount.replace(",", "") : "0"))
              ).map((key, index) => (
                onlyLargeScreen ?
                  key.data && !key.isError && <Quote
                    key={('amount' in key.data) ? key.data.amount : index.toString()}
                    quoteSize="h4"
                    provider={('provider' in key.data) ? key.data.provider : undefined}
                    amount={key.data?.amount}
                  />
                  : (
                    key.data && !key.isError && <QuoteSmall
                      key={('amount' in key.data) ? key.data.amount : index.toString()}
                      provider={('provider' in key.data) ? key.data.provider : undefined}
                      amount={key.data?.amount}
                    />
                  )
              ))) : (<>404 Sorry we coudln't find that page</>)
              }
            </Grid>
          )
            :
            (
              <StyledMessageBox>
                <Typography color={theme.palette.text.secondary} align="center" variant="h3">An error occured</Typography>
                <Typography color={theme.palette.text.secondary} align="center" variant="h6">Sorry we couldn't retrieve any quotes that match your search. Please try again later.</Typography>
              </StyledMessageBox>
            )
        )}
        {loaded > 0 && <Divider sx={{ width: '100%' }} /> && <Footer />}
      </StyledBox>
    </PageContainer>
  );
}

