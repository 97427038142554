import {
    Box,
    Divider,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import Footer from "../components/Footer";
import AppAppBar from "../components/AppAppBar";
import { AppBarDivider } from "../common/styles";

const StyledBox = styled(Box)(({ theme }) => ({
    height: "80vh",
    width: "100vw",
    overflow: "hidden"
}));

const StyledMessageBox = styled(Box)(({ theme }) => ({
    height: "50%",
    display: "flex",
    width: "100vw",
    justifyContent: "center",
    flexDirection: "column",
}));

export function NotFound(): React.ReactElement {
    const theme = useTheme()
 
    return (
        <div style={{width: "80%"}}>
        <StyledBox justifyContent={"space-between"}>
            <AppAppBar mode="light"/>
            <AppBarDivider/>
            <StyledMessageBox>
                <Typography color={theme.palette.text.secondary} align="center" variant="h3">404</Typography>
                <Typography color={theme.palette.text.secondary} align="center" variant="h6">Sorry we couldn't find what you were looking for 😢</Typography>
            </StyledMessageBox>
            
        </StyledBox>
        <Divider sx={{ width: '100%' }} />
        <Footer/>
        </div>
    );
}
